import { Mesh, Vector3, Scene } from "@babylonjs/core";
import { getMiscNodes } from "./AirDividersHandlers";

const findVisibleNodeByName = (nodeName: string, scene: Scene, task = "") => {
  return scene.getTransformNodesById(nodeName).find((node) => {
    const rootNode = node.parent?.parent;
  
    if (rootNode instanceof Mesh) {
      if (!task) {
        return rootNode.isVisible;
      }
      return rootNode.isVisible && node.metadata.task.includes(task);
    }
    return false;
  });
};
  
const getWindowsPositionNodes = (scene: Scene) => {
  const topLeftNode = findVisibleNodeByName("bone_frame_top_left", scene);
  const topRightNode = findVisibleNodeByName("bone_frame_top_right", scene);
  const bottomLeftNode = findVisibleNodeByName("bone_frame_bottom_left", scene);
  const bottomRightNode = findVisibleNodeByName(
    "bone_frame_bottom_right",
    scene
  );
  
  return { topLeftNode, topRightNode, bottomLeftNode, bottomRightNode };
};

const getTransformNodesById = (id, scene) => {
  if (!id || !scene) {
    return;
  }

  return scene
    .getTransformNodesById(id)
    .find((node) => node?.parent?.parent?.isVisible);
};
  
export const applyDividers = (scene) => {
  const { bottomLeftNode, topLeftNode, topRightNode, bottomRightNode } =
      getWindowsPositionNodes(scene);
  
  if (!bottomLeftNode || !topLeftNode || !topRightNode || !bottomRightNode) {
    return;
  }
  
  const bottomHorizontalLeftBoneDivider = getTransformNodesById(
    "bone_grills_horizontal_bottom_left",
    scene
  );
  const topHorizontalLeftBoneDivider = getTransformNodesById(
    "bone_grills_horizontal_top_left",
    scene
  );
  const topHorizontalRightBoneDivider = getTransformNodesById(
    "bone_grills_horizontal_top_right",
    scene
  );
  const bottomHorizontalRightBoneDivider = getTransformNodesById(
    "bone_grills_horizontal_bottom_right",
    scene
  );
  const bottomVerticalLeftBoneDivider = getTransformNodesById(
    "bone_grills_vertical_bottom_left",
    scene
  );
  const bottomVerticalRightBoneDivider = getTransformNodesById(
    "bone_grills_vertical_bottom_right",
    scene
  );
  const topVerticalLeftBoneDivider = getTransformNodesById(
    "bone_grills_vertical_top_left",
    scene
  );
  const topVerticalRightBoneDivider = getTransformNodesById(
    "bone_grills_vertical_top_right",
    scene
  );
  
  const boneFrameLBottomLeft = getTransformNodesById(
    "bone_grills_horizontal_L_bottom_left",
    scene
  );
  const boneFrameLBottomRight = getTransformNodesById(
    "bone_grills_horizontal_L_bottom_right",
    scene
  );
  const boneFrameLTopLeft = getTransformNodesById(
    "bone_grills_horizontal_L_top_left",
    scene
  );
  const boneFrameLTopRight = getTransformNodesById(
    "bone_grills_horizontal_L_top_right",
    scene
  );
  
  const boneFrameRBottomLeft = getTransformNodesById(
    "bone_grills_horizontal_R_bottom_left",
    scene
  );
  const boneFrameRBottomRight = getTransformNodesById(
    "bone_grills_horizontal_R_bottom_right",
    scene
  );
  const boneFrameRTopLeft = getTransformNodesById(
    "bone_grills_horizontal_R_top_left",
    scene
  );
  const boneFrameRTopRight = getTransformNodesById(
    "bone_grills_horizontal_R_top_right",
    scene
  );

  const firstMiscNodes = getMiscNodes("misc0", scene);

  if (!firstMiscNodes) {
    if (
      bottomHorizontalLeftBoneDivider &&
        topHorizontalLeftBoneDivider &&
        topHorizontalRightBoneDivider &&
        bottomHorizontalRightBoneDivider
    ) {
      bottomHorizontalLeftBoneDivider.position = new Vector3(
        bottomLeftNode.position.x,
        bottomLeftNode.position.y
      );
      topHorizontalLeftBoneDivider.position = new Vector3(
        topLeftNode.position.x,
        topLeftNode.position.y
      );
      topHorizontalRightBoneDivider.position = new Vector3(
        topRightNode.position.x,
        topRightNode.position.y
      );
      bottomHorizontalRightBoneDivider.position = new Vector3(
        bottomRightNode.position.x,
        bottomRightNode.position.y
      );
    }
  
    if (
      boneFrameLBottomLeft &&
        boneFrameLBottomRight &&
        boneFrameLTopLeft &&
        boneFrameLTopRight
    ) {
      boneFrameLBottomLeft.position = new Vector3(
        bottomLeftNode.position.x,
        bottomLeftNode.position.y
      );
      boneFrameLBottomRight.position = new Vector3(
        bottomRightNode.position.x,
        bottomRightNode.position.y
      );
      boneFrameLTopLeft.position = new Vector3(
        topLeftNode.position.x,
        topLeftNode.position.y
      );
      boneFrameLTopRight.position = new Vector3(
        topRightNode.position.x,
        topRightNode.position.y
      );
      boneFrameRBottomLeft.position = new Vector3(
        bottomLeftNode.position.x,
        bottomLeftNode.position.y
      );
      boneFrameRBottomRight.position = new Vector3(
        bottomRightNode.position.x,
        bottomRightNode.position.y
      );
      boneFrameRTopLeft.position = new Vector3(
        topLeftNode.position.x,
        topLeftNode.position.y
      );
      boneFrameRTopRight.position = new Vector3(
        topRightNode.position.x,
        topRightNode.position.y
      );
    }

    if (
      bottomVerticalLeftBoneDivider &&
        bottomVerticalRightBoneDivider &&
        topVerticalLeftBoneDivider &&
        topVerticalRightBoneDivider) {

      bottomVerticalLeftBoneDivider.position = new Vector3(
        bottomLeftNode.position.x,
        bottomLeftNode.position.y
      );
      bottomVerticalRightBoneDivider.position = new Vector3(
        bottomRightNode.position.x,
        bottomRightNode.position.y
      );
      topVerticalLeftBoneDivider.position = new Vector3(
        topLeftNode.position.x,
        topLeftNode.position.y
      );
      topVerticalRightBoneDivider.position = new Vector3(
        topRightNode.position.x,
        topRightNode.position.y
      );
    }
  }

};
